import React, {useState} from 'react';
import styles from './login.css';
import classNames from 'classnames';
import {message} from 'antd';

const userCredentials = new Map([
    ['cyy', '13579'],
    ['linshi', '13579']
    // ... 可以添加更多用户
]);

const Login = (props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        if (userCredentials.has(username) && userCredentials.get(username) === password) {
            message.success("登录成功");
            window.sessionStorage.setItem('login', 'true');
            window.location.replace(window.location.pathname);
        } else {
            message.error("用户名或密码错误！");
        }
    };

    return (
        <div className={classNames(styles.loginBody)}>
            <div className={classNames(styles.loginContainer)}>
                <form onSubmit={handleSubmit} className={classNames(styles.loginForm)}>
                    <h2 className={classNames(styles.loginFormH2)}>登录</h2>
                    <div className={classNames(styles.formGroup)}>
                        <label htmlFor="username">用户名:</label>
                        <input
                            type="text"
                            id="username"
                            className={classNames(styles.formControl)}
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <div className={classNames(styles.formGroup)}>
                        <label htmlFor="password">密码:</label>
                        <input
                            type="password"
                            id="password"
                            className={classNames(styles.formControl)}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <button disabled={username==='' || password===''}
                            type="submit"
                            className={
                        classNames((username==='' || password==='')?styles.btnDisable:styles.btn)}
                    >Login</button>
                </form>
            </div>
            <a className={classNames(styles.a)}
               href={'https://beian.miit.gov.cn/#/Integrated/index'}
               target="_blank"
            >
                浙ICP备2024098702号
            </a>
        </div>
    );
};

export default Login;
