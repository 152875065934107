import React from 'react';
import Login from './components/login/Login';

const withAuth = Component => {
    const isLoggedIn = () => {
        // 判断用户是否已登录
        return window.sessionStorage.getItem('login') === 'true'; // 假设用户已登录
    };

    return props => {
        if (isLoggedIn()) {
            // 用户已登录，直接渲染被包装的组件
            return <Component {...props} />;
        } else {
            // 用户未登录，跳转到登录页面
            return <Login props={props}></Login>;
        }
    };
};
export default withAuth;
